<template>

    <div class="h-auto w-full rounded-lg module p-2 flex flex-col relative">

        <loader :loading="loading"/>

        <div class="h-auto px-2 flex flex-row justify-start items-center">
            <span class="text-xxs font-semibold text-font-gray"> <span class="text-white">YTD</span> & Y-1 & <span class="text-plan">PLAN</span></span>
        </div>

        <div class="h-auto pl-2 ">

            <div class="h-4 flex flex-row">
                <div class="h-full w-full flex flex-col justify-center items-center">
                    <div class="h-1 w-full rounded-full bg-body">
                        <div class="h-full bg-success rounded-full" :style="'width:' + ytd_chargeability + '%'"></div>
                    </div>
                </div>
                <div class="h-full w-2/12 ml-auto flex flex-col justify-center items-center">
                    <span class="text-white font-semibold text-xxs">{{ ytd_chargeability | numberFormat(2) }}%</span>
                </div>
            </div>

            <div class="h-4 flex flex-row">
                <div class="h-full w-full flex flex-col justify-center items-center">
                    <div class="h-1 w-full rounded-full bg-body">
                        <div class="h-full bg-font-gray rounded-full" :style="'width:' + ytd_1_chargeability + '%'"></div>
                    </div>
                </div>
                <div class="h-full w-2/12 ml-auto flex flex-col justify-center items-center">
                    <span class="text-font-gray font-semibold text-xxs">{{ ytd_1_chargeability | numberFormat(2) }}%</span>
                </div>
            </div>

            <div class="h-4 flex flex-row">
                <div class="h-full w-full flex flex-col justify-center items-center">
                    <div class="h-1 w-full rounded-full bg-body">
                        <div class="h-full bg-plan rounded-full" :style="'width:' + plan + '%'"></div>
                    </div>
                </div>
                <div class="h-full w-2/12 ml-auto flex flex-col justify-center items-center">
                    <span class="text-plan font-semibold text-xxs">{{ plan || 0 | numberFormat(2) }}%</span>
                </div>
            </div>

        </div>

    </div>

</template>

<script>

    import { state } from '@/store';

    export default {
        name: "PlanYTDAndY1",
        data() {
            return {
                loading: false,
                ytd_1_chargeability: 0,
                ytd_chargeability: 0,
                plan: 0
            }
        },
        methods: {
            load() {
                this.loading = true
                this.axios.get('chargeability/ytd', { params: this.params }).then(response => {
                    this.loading = false;
                    this.ytd_1_chargeability = response.data.data.ytd_1;
                    this.ytd_chargeability = response.data.data.ytd;
                    this.plan = response.data.data.plan;
                })
            }
        },
        computed: {
            params() {
                let params = state.globalParams
                if (this.$route.params.catId) {
                    params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
                }
                return params
            }
        },
        watch:{
            params(){
                this.load();
            }
        },
        mounted(){ 
            if(this.params){
                this.load()
            }
        }
    }
</script>